@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

/* Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #222;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 10rem;
}

.nav-list {
  display: flex;
  list-style: none;
}

.nav-item {
  margin-right: 2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.nav-item:hover {
  color: #0079bf;
}

.active {
  color: #0079bf;
  border-bottom: 2px solid #0079bf;
}

.search-box {
  margin-left: auto;
}

.search-input {
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
  background-color: #f4f5f7;
  border-radius: 3px;
}

.profile {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.name {
  font-size: 1.8rem;
  font-weight: 500;
}

/* Boards */
.boards {
  padding: 2rem;
}

.title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.board-list {
  display: flex;
  flex-wrap: wrap;
}

.board {
  flex-basis: 30%;
  background-color: #fff;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.board:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.board-title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.board-tasks {
  margin-top: 1.5rem;
}

.board-task {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  background-color: #f4f5f7;
  margin-bottom: 1rem;
}

.board-task:hover {
  background-color: #e2e4e7;
}

.board-task-title {
  font-size: 1.6rem;
  font-weight: 500;
}

.board-task-assignee {
  font-size: 1.4rem;
  color: #5e6c84;
}

@media (max-width: 768px) {
    .header {
      flex-wrap: wrap;
    }
  
    .nav-list {
      width: 100%;
      margin-bottom: 1rem;
      justify-content: space-between;
    }
  
    .nav-item {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  
    .search-box {
      margin-left: 0;
      margin-top: 1rem;
      width: 100%;
    }
  
    .profile {
      margin-left: 0;
      margin-top: 1rem;
    }
  
    .board {
      flex-basis: 48%;
      margin-right: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .board {
      flex-basis: 100%;
      margin-right: 0;
    }
  }
  